export default {
    filterTemplate: {
        partnerTypes: [],
        branchCodes: [],
        emailExists: true,
        excludeInternalEmail: true,
        cultivationTreeNodeIds: [],
        limit: 100,
        offset: 0,
        minRating: 10,
        maxGuestContacts: 2,
        classifierItems: [],
        classifiersUnionBy: 'and',
        productCategoryTags: [],
        selectedModels: [],
        dateRange: {
            start: null,
            end: null,
        },
        orders: {
            order_created: {
                start: null,
                end: null,
            },
            product_type: null,
            order_status_id: [],
            closing_status_id: [],
            close_reason_id: [],
            tech_categories_id: [],
            tech_models: [],
            without_closed: 0,
        },
    },
};
