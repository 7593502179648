<template src="./templates/partner-contacts.html"></template>

<script>
import Multiselect from '@vueform/multiselect';
import '../../layouts/default/styles/partners.scss';
import helpers from '@/tools/helpers';

import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';

import {
    deletePartnerContactFilter,
    downloadPartnerContactsCsv,
    findPartnersContacts,
    getClassifierItemTree,
    getCultivationTechnologyTree,
    getPartnerContactFilters,
    getPartnerOrdersFilter,
    getPartnerTypes,
    savePartnerContactFilter
} from '@api/partners';

import {getBranches} from '@api/layout';

import Input from '@f/Input';
import TreeSelect from '@f/TreeSelect';
import FormCheckbox from '@f/Check';
import Tree from '@c/Tree';
import Grid from '@c/Grid';
import DateRangePicker from '@c/DateRangePicker';
import Button from '@f/Button';
import Loader from '@c/Loader';
import FormRadio from '@f/Radio/index';
import {findModelsByCatalogCategory, findModelsCategory, GetCatalogCategoryTree} from '@api/product';
import config from './config';
import DropdownTree from './components/dropdown-tree';


export default {
        name: 'PartnerContacts',
        components: {
            PageTitle,
            Breadcrumbs,
            PageHeader,
            FormRadio,
            Button,
            Grid,
            Tree,
            Multiselect,
            Input,
            TreeSelect,
            FormCheckbox,
            DropdownTree,
            Loader,
            DateRangePicker,
        },
        props: {
            size: {
                type: Number,
                required: false,
                default: 100
            },
        },
        data() {
            return {
                loading: false,
                partnerTypes: [],
                cultivationTreeNodes: [],
                classifier: [],
                branches: [],
                partners: null,
                pageNumber: 0,
                allPages: 0,
                partnersCount: 0,
                partnerList: [],
                productCategoryTagsTree: [],
                modelsOptions: [],

                productTypesOptions: [],
                orderStatusOptions: [],
                closingStatusOptions: [],
                closeReasonsOptions: [],
                techCategoriesOptions: [],
                lastOrderStatusOptions: [],

                selectionValues: {
                    ...helpers.deepCopy(config.filterTemplate)
                },

                // Сохраненные фильтры
                favoriteFilters: {
                    list: [],
                    selected: null,
                    newFilterName: '',
                    showSaveFilterInput: false,
                    isSavedFilter: false,
                    filterRequestLoading: false,
                },
            };
        },
        watch: {
            'selectionValues.productCategoryTags': {
                immediate: true,
                handler(newVal) {
                    this.loadModelsOptions(newVal);
                },
            },

            'selectionValues.orders.tech_categories_id': {
                immediate: true,
                handler(newVal) {
                    this.loadOrderModelsOptions(newVal);
                },
            },
        },
        async created() {
            this.branches = await getBranches();
            const types = await getPartnerTypes();
            this.partnerTypes = Object.values(types);
            const tree = await getCultivationTechnologyTree(1);
            this.cultivationTreeNodes = tree.reduce((acc, item) => [...acc, ...item.children], []);
            this.classifier = await getClassifierItemTree();
            this.productCategoryTagsTree = await GetCatalogCategoryTree();

            await this.buildOrdersFilter();
        },
        mounted() {
            this.loadFavorites();
        },
        methods: {
            async buildOrdersFilter() {
                const filter = await getPartnerOrdersFilter();
                this.productTypesOptions = filter?.product_types;
                this.orderStatusOptions = filter?.order_statuses;
                this.closingStatusOptions = filter?.order_close_statuses;
                this.closeReasonsOptions = filter?.order_close_reasons;
                this.techCategoriesOptions = filter?.product_category_tree;
            },
            async loadModelsOptions(catalogCategoryIds) {
                    this.modelsOptions = await findModelsByCatalogCategory({catalogCategoryIds});
            },
            async loadOrderModelsOptions(categoryIds) {
                    this.modelsOptions = await findModelsCategory({categoryIds});
            },
            async nextPage() {
                this.pageNumber++;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async prevPage() {
                this.pageNumber--;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async openPage(key) {
                this.pageNumber = key;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async requestData(offset) {
                this.loading = true;

                this.selectionValues.offset = offset;
                this.partnerList = await findPartnersContacts(this.selectionValues);
                this.partnersCount = await findPartnersContacts(this.selectionValues, true);
                this.allPages = Math.ceil(this.partnersCount / this.selectionValues.limit);

                this.loading = false;
            },
            async applyFilters() {
                this.pageNumber = 0;
                await this.requestData(0);
            },
            async downloadFile() {
                this.loading = true;

                const data = await downloadPartnerContactsCsv({
                    ...this.selectionValues,
                    offset: undefined,
                    limit: undefined
                });
                this.saveFile(data, 'temp.csv');

                this.loading = false;
            },
            saveFile(data, fileName) {
                const blob = new Blob([data], {type: 'application/csv'});

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            categoryTreeNormalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                };
            },

            resetFilter() {
                this.selectionValues = helpers.deepCopy(config.filterTemplate);
            },

            createFilter() {
                this.resetFilter();
                this.favoriteFilters.selected = null;
                this.favoriteFilters.newFilterName = '';
                this.toggleSaveSearch();
            },
            async deleteFilter() {
                this.favoriteFilters.filterRequestLoading = true;
                await deletePartnerContactFilter({
                    id: this.favoriteFilters.selected.id
                }).then(() => {
                    this.resetFilter();

                    this.favoriteFilters.list = this.favoriteFilters.list.filter(item => item.id !== this.favoriteFilters.selected.id);
                    this.favoriteFilters.selected = null;
                    this.favoriteFilters.newFilterName = '';
                    this.favoriteFilters.isSavedFilter = false;
                }).finally(() => {
                    this.favoriteFilters.filterRequestLoading = false;
                });
            },
            async loadFavorites() {
                try {
                    this.favoriteFilters.filterRequestLoading = true;
                    this.favoriteFilters.list = await getPartnerContactFilters().finally(() => {
                        this.favoriteFilters.filterRequestLoading = false;
                    });
                } catch (error) {
                    console.error('Error loading favorites:', error);
                }
            },
            async saveSearch() {
                if (!this.favoriteFilters.newFilterName) {
                    console.log('Введите имя поиска');
                    return;
                }

                try {
                    this.favoriteFilters.filterRequestLoading = true;

                    const response = await savePartnerContactFilter({
                        name: this.favoriteFilters.newFilterName,
                        params: this.selectionValues
                    }).finally(() => {
                        this.favoriteFilters.filterRequestLoading = false;
                    });

                    this.favoriteFilters.list = [...this.favoriteFilters.list, response];
                    this.favoriteFilters.showSaveFilterInput = false;
                    this.favoriteFilters.newFilterName = '';
                    this.favoriteFilters.isSavedFilter = true;
                    this.favoriteFilters.selected = response;
                } catch (error) {
                    console.error('Error saving search:', error);
                }
            },
            async updateFilter() {
                if (this.favoriteFilters.selected) {
                    try {
                        this.favoriteFilters.filterRequestLoading = true;

                        const response = await savePartnerContactFilter({
                            id: this.favoriteFilters.selected.id,
                            name: this.favoriteFilters.selected.name,
                            params: this.selectionValues
                        }).finally(() => {
                            this.favoriteFilters.filterRequestLoading = false;
                        });

                        this.favoriteFilters.selected = response;
                        this.favoriteFilters.list = this.favoriteFilters.list.map(filter => {
                            if (filter.id === response.id) {
                                return response;
                            }
                            return filter;
                        });
                    } catch (error) {
                        console.error('Error updating search:', error);
                    }
                }
            },
            async loadFavorite() {
                if (this.favoriteFilters.selected) {
                    try {
                        this.selectionValues = this.favoriteFilters.selected.params;
                        this.favoriteFilters.isSavedFilter = true;
                        this.favoriteFilters.showSaveFilterInput = false;
                    } catch (error) {
                        console.error('Error loading favorite:', error);
                    }
                }
            },
            toggleSaveSearch() {
                this.favoriteFilters.showSaveFilterInput = !this.favoriteFilters.showSaveFilterInput;
            },
        }
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.toggle-container {
    margin: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;

    .toggle-checkbox {
        display: none;
    }

    .toggle-label {
        display: inline-block;
        cursor: pointer;
        padding: 10px;
        background-color: #3498db;
        color: white;
        border-radius: 5px;
    }

    .toggle-content {
        display: none;
        margin-top: 10px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;

        .toggle-container {
            margin-top: 10px;
        }
    }

    .toggle-checkbox:checked {
        & ~ .toggle-content {
            display: block;
        }
    }
}
.filter-list {
    .toggle-content {
        height: 110px;
    }

    .filter-list__button {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.2s, color 0.2s;
    }

    .filter-list__button:hover {
        background-color: #f0f0f0;
    }

    .filter-list__button--primary {
        background-color: #007bff;
        color: white;
        border-color: #007bff;
    }

    .filter-list__button--primary:hover {
        background-color: #0056b3;
        border-color: #0056b3;
    }

    .filter-list__button--danger {
        background-color: #dc3545;
        color: white;
        border-color: #dc3545;
    }

    .filter-list__button--danger:hover {
        background-color: #c82333;
        border-color: #c82333;
    }

    .filter-list__button--success {
        background-color: #28a745;
        color: white;
        border-color: #28a745;
    }

    .filter-list__button--success:hover {
        background-color: #218838;
        border-color: #218838;
    }

    .filter-list__input-group {
        display: flex;
        gap: 5px;
    }

    .filter-list__input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
    }

    .filter-list__select {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        width: 100%;
    }

    .filter-list__favorites.active {
        display: flex;
    }
}

</style>
