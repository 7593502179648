<div>

    <page-header>
        <div class="row c">
            <breadcrumbs :items="[{title:'Контрагенты'}, {title:'Отбор клиентов'}]"/>
        </div>
    </page-header>

    <page-title title="Отбор контактных лиц клиентов" :params="{/*subtitle:'Список переменных'*/}"/>

    <div class="container">
        <div class="row filter-list">
            <div class="col-12 filter-list__actions">
                <div class="toggle-container">
                    <input type="checkbox" id="toggle-checkbox-filter-favorites" class="toggle-checkbox">
                    <label for="toggle-checkbox-filter-favorites" class="toggle-label">Избранные фильтры</label>
                    <div class="toggle-content row mb-2 border-dashed">
                        <div class="col-12" v-if="this.favoriteFilters.filterRequestLoading">
                            <Loader />
                        </div>
                        <div class="col-12" v-else>
                            <div class="row mb-2">
                                <div
                                    v-if="favoriteFilters.showSaveFilterInput"
                                    class="filter-list__input-group col-6"
                                >
                                    <input
                                        type="text"
                                        v-model="favoriteFilters.newFilterName"
                                        placeholder="Заполните имя фильтра"
                                        class="filter-list__input"
                                    />
                                    <button @click="saveSearch" class="filter-list__button filter-list__button--primary">
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="filter-list__favorites col-6">
                                    <select
                                        v-model="favoriteFilters.selected"
                                        @change="loadFavorite"
                                        class="filter-list__select"
                                        title="Список сохранённых фильтров"
                                    >
                                        <option
                                            v-for="favorite in favoriteFilters.list"
                                            :key="favorite.id"
                                            :value="favorite"
                                        >
                                            {{ favorite.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="filter-list__management filter-list__input-group col-5">
                                    <button
                                        @click="createFilter"
                                        class="filter-list__button filter-list__button--primary"
                                        title="Создать новый фильтр"
                                    >
                                        Новый
                                    </button>
                                    <button
                                        v-if="favoriteFilters.selected"
                                        @click="deleteFilter"
                                        :disabled="!favoriteFilters.selected"
                                        class="filter-list__button filter-list__button--danger"
                                        title="Удалить текущий выбранный фильтр"
                                    >
                                        Удалить
                                    </button>
                                    <button
                                        v-if="favoriteFilters.selected"
                                        @click="updateFilter"
                                        :disabled="!favoriteFilters.selected"
                                        class="filter-list__button filter-list__button--success"
                                        title="Перезаписать выбранный фильтр новыми значениями"
                                    >
                                        Перезаписать
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row filter-block mb-3">
            <div class="col-12">
                <div class="row mb-2">
                    <div class="col-4">
                        <label>Тип контрагента</label>
                        <Multiselect
                            v-model="selectionValues.partnerTypes"
                            :options="partnerTypes"
                            :label="'name'"
                            placeholder="Выберите из списка"
                            valueProp="id"
                            trackBy="name"
                            mode="tags"
                            :searchable="true"
                        />
                    </div>
                    <div class="col-3">
                        <label>Филиал</label>
                        <Multiselect
                            v-model="selectionValues.branchCodes"
                            :options="branches"
                            :label="'full_name'"
                            placeholder="Выберите из списка"
                            valueProp="id"
                            trackBy="full_name"
                            mode="tags"
                            :searchable="true"
                            :disabled="false"
                        />
                    </div>
                    <div class="col-2 mt-3">
                        <FormCheckbox
                            v-model="selectionValues.emailExists"
                            label="Наличие email"
                        />
                    </div>
                    <div class="col-3 mt-3">
                        <FormCheckbox
                            v-model="selectionValues.excludeInternalEmail"
                            label="Исключить email`ы сотрудников"
                        />
                    </div>
                </div>
                <div class="toggle-container">
                    <input type="checkbox" id="toggle-checkbox-classifier" class="toggle-checkbox">
                    <label for="toggle-checkbox-classifier" class="toggle-label">Фильтр по классификаторам</label>
                    <div class="toggle-content row mb-2 border-dashed">
                        <div class="col-12">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <label>Классификатор 1С</label>
                                    <DropdownTree
                                        :mode="'edit'"
                                        v-model:classifier="classifier"
                                        v-model:selectedItems="selectionValues.classifierItems"
                                    />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <FormRadio
                                    v-model="selectionValues.classifiersUnionBy"
                                    :value="'and'"
                                    label="И"
                                    name="unionBy"
                                    wrapper-class="inline"
                                />
                                <FormRadio
                                    v-model="selectionValues.classifiersUnionBy"
                                    :value="'or'"
                                    label="ИЛИ"
                                    name="unionBy"
                                    wrapper-class="inline"
                                />
                            </div>
                            <div class="row mb-2">
                                <div class="col-12">
                                    <label>Веб классификатор</label>
                                    <Multiselect
                                        v-model="selectionValues.cultivationTreeNodeIds"
                                        :options="cultivationTreeNodes"
                                        :label="'name'"
                                        placeholder="Выберите из списка"
                                        valueProp="id"
                                        trackBy="name"
                                        mode="tags"
                                        :searchable="true"
                                    />
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-6">
                                    <label>Минимальный рейтинг по веб-классификатору</label>
                                    <Input
                                        :placeholder="'Введите число'"
                                        v-model="selectionValues.minRating"
                                    />
                                </div>
                                <div class="col-6">
                                    <label>Макс. кол-во контактных лиц на гостя</label>
                                    <Input
                                        :placeholder="'Введите число'"
                                        v-model="selectionValues.maxGuestContacts"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="toggle-container">
                    <input type="checkbox" id="toggle-checkbox-interests" class="toggle-checkbox">
                    <label for="toggle-checkbox-interests" class="toggle-label">Фильтр по интересам</label>
                    <div class="toggle-content row mb-2">
                        <div class="col-12">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <label>Товарное направление</label>
                                    <tree-select
                                        v-model:value="selectionValues.productCategoryTags"
                                        :options="productCategoryTagsTree"
                                        placeholder="Выберите товарные направления..."
                                        :disable-branch-nodes="false"
                                    />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12">
                                    <label>Модели</label>
                                    <Multiselect
                                        v-model="selectionValues.selectedModels"
                                        :options="modelsOptions"
                                        :label="'name'"
                                        placeholder="Выберите модели..."
                                        valueProp="id"
                                        trackBy="name"
                                        mode="tags"
                                        :searchable="true"
                                    />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">
                                    <label>Период дат</label>
                                    <DateRangePicker
                                        v-model="selectionValues.dateRange"
                                    />
                                </div>
                                <div class="col-8">
                                    <label>Макс. кол-во контактных лиц на гостя</label>
                                    <Input
                                        :placeholder="'Введите число'"
                                        v-model="selectionValues.maxGuestContacts"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="toggle-container">
                    <input type="checkbox" id="toggle-checkbox-orders" class="toggle-checkbox">
                    <label for="toggle-checkbox-orders" class="toggle-label">Фильтр по заказам</label>
                    <div class="toggle-content row mb-2 border-dashed">
                        <div class="col-12">
                            <div class="row mb2">
                                <!-- Фильтр по дате создания заказа -->
                                <div class="col-6">
                                    <label>Дата создания заказа</label>
                                    <DateRangePicker
                                        v-model="selectionValues.orders.order_created"
                                    />
                                </div>

                                <!-- Фильтр по продукту -->
                                <div class="col-6">
                                    <label>Тип продукта</label>
                                    <Multiselect
                                        v-model="selectionValues.orders.product_type"
                                        :options="productTypesOptions"
                                        :label="'name'"
                                        placeholder="Выберите тип продукта"
                                        valueProp="id"
                                        trackBy="name"
                                        mode="tags"
                                        :searchable="true"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mb2">
                                <!-- Фильтр по статусу заказа -->
                                <div class="col-4">
                                    <label>Статус заказа</label>
                                    <Multiselect
                                        v-model="selectionValues.orders.order_status_id"
                                        :options="orderStatusOptions"
                                        :label="'name'"
                                        placeholder="Выберите статус заказа"
                                        valueProp="id"
                                        trackBy="name"
                                        mode="tags"
                                        :searchable="true"
                                    />
                                </div>

                                <!-- Фильтр по закрывающему статусу -->
                                <div class="col-4">
                                    <label>Статус закрытия</label>
                                    <Multiselect
                                        v-model="selectionValues.orders.closing_status_id"
                                        :options="closingStatusOptions"
                                        :label="'name'"
                                        placeholder="Выберите статус закрытия"
                                        valueProp="id"
                                        trackBy="name"
                                        mode="tags"
                                        :searchable="true"
                                    />
                                </div>

                                <div class="col-4">
                                    <label>Причина закрытия</label>
                                    <Multiselect
                                        v-model="selectionValues.orders.close_reason_id"
                                        :options="closeReasonsOptions"
                                        :label="'reason'"
                                        placeholder="Выберите причину"
                                        valueProp="id"
                                        trackBy="reason"
                                        mode="tags"
                                        :searchable="true"
                                    />
                                </div>

                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mb2">
                                <!-- Фильтр по категориям -->
                                <div class="col-12">
                                    <label>Категории техники</label>
                                    <tree-select
                                        v-model:value="selectionValues.orders.tech_categories_id"
                                        :options="techCategoriesOptions"
                                        placeholder="Выберите категории техники..."
                                        :normalizer="categoryTreeNormalizer"
                                        :disable-branch-nodes="false"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mb2">
                                <!-- Фильтр по моделям -->
                                <div class="col-12">
                                    <label>Модели</label>
                                    <Multiselect
                                        v-model="selectionValues.orders.tech_models"
                                        :options="modelsOptions"
                                        :label="'name'"
                                        placeholder="Выберите модели..."
                                        valueProp="id"
                                        trackBy="name"
                                        mode="tags"
                                        :searchable="true"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mb2">
                                <!-- Фильтр исключения статуса -->
                                <div class="col-4">
                                    <label>&nbsp;</label>
                                    <FormCheckbox
                                        v-model="selectionValues.orders.without_closed"
                                        label="Без выполненных"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-3 mt-2">
                        <span>Найдено результатов ({{partnersCount}})</span>
                    </div>
                    <div class="col-3">
                        <Button
                            type="a"
                            label="Экcпорт CSV"
                            href="#"
                            @click.prevent.stop="downloadFile"
                        />
                    </div>
                    <div class="col-3">
                        <Button
                            type="a"
                            label="Применить фильтры"
                            href="#"
                            @click.prevent.stop="applyFilters"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <Loader v-if="this.loading"/>
            <Grid
                v-else
                :columns="[
                {
                    name: 'partner_name',
                    label: 'Контрагент',
                    width: 'minmax(20px, 0.50fr)',
                },
                {
                    name: 'partner_full_name',
                    label: 'Контрагент, полное имя',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'name',
                    label: 'Контактное лицо',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'first_name',
                    label: 'Контактное лицо, имя',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'middle_name',
                    label: 'Контактное лицо, отчество',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'email',
                    label: 'e-mail',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'partner_external_id',
                    label: 'ID клиента',
                    width: 'minmax(50px, 1fr)',
                },
            ]"
                :data="partnerList"
                :onEdit="undefined"
                :onRemove="undefined"
            >
            </Grid>
        </div>
        <div class="pagination__wrapper">
            <ul class="pagination">
                <li>
                    <button class="prev" @click="prevPage" :disabled="pageNumber===0">&#10094;</button>
                </li>
                <template v-for="(number,key) in allPages">
                    <li @click="key !== pageNumber ? openPage(key) : ''"
                        v-if="key === 0 || number === allPages || (pageNumber + 7 >= key && pageNumber - 7 <= key)">
                        <button
                            v-bind:class="{'active':pageNumber === key,'side_numbers':key === 0 || number === allPages}">
                            {{ number }}
                        </button>
                    </li>
                </template>
                <li>
                    <button class="next" @click="nextPage" :disabled="pageNumber">&#10095;</button>
                </li>
            </ul>
        </div>
    </div>
</div>
